import PropTypes from "@arc-fusion/prop-types";
import React, { Fragment } from "react";
import { getClasses } from "@washingtonpost/front-end-utils";
import getEnv from "~/components/utilities/env";
import { useLiveTicker } from "~/content/sources/live-ticker";
import {
  makeIsSameContent,
  transformFromAnsToFetched
} from "./LiveTicker.helpers";

/* Components */
import { CompoundLabel } from "./Label";

const env = getEnv();

// Fetch every 10 sec
const defaultPollFrequency = 10;

const LiveTicker = ({
  url,
  isLive,
  pollFrequency = defaultPollFrequency,
  subtypes,
  items = [],
  numToShow,
  showTimestamps,
  compoundLabel
}) => {
  const refreshInterval =
    env === "prod"
      ? defaultPollFrequency * 1000
      : Math.max(defaultPollFrequency, pollFrequency) * 1000;
  const { items: fetchedItems } = useLiveTicker({
    url,
    subtypes,
    isLive,
    content: { content: { children: items.map(transformFromAnsToFetched) } },
    isSameContent: makeIsSameContent(numToShow),
    INTERVAL: refreshInterval
  });

  const itemsToUse = (fetchedItems.length === 0 ? items : fetchedItems).slice(
    0,
    numToShow
  );

  if (!itemsToUse || !itemsToUse.length) return null;

  const listClass =
    "blt blt-outside blt-tight blt-connected blt-dot-large blt-red left relative";
  const timestampClass = "gray-dark";
  const itemHeadlineClass = "font-xxxs gray-darker link";
  const paddingClass = "pb-xs";

  return (
    <Fragment>
      {compoundLabel && <CompoundLabel {...compoundLabel} />}
      <ul
        className={`list-none pa-0 ma-0 left ${paddingClass}`}
        data-link-group="live-ticker"
      >
        {itemsToUse.map((item, index) => {
          let timestamp;
          try {
            timestamp = new Date(item.display_date).toLocaleString("en-US", {
              timeZone: "America/New_York",
              hour: "numeric",
              minute: "2-digit"
            });
          } catch (ex) {
            timestamp = item.display_date;
          }

          if (index <= numToShow - 1)
            return (
              <li
                // key={`liveTicker_${index}`}
                key={`${item.canonical_url}-${index}`}
                className={getClasses(listClass, {
                  [paddingClass]: index !== itemsToUse.length - 1
                })}
              >
                <a
                  href={item.canonical_url}
                  key={`${item.display_date}_${index}`}
                  data-link-detail={index + 1}
                  className={itemHeadlineClass}
                >
                  {showTimestamps && (
                    <div className={timestampClass}>
                      {timestamp.replace(/\b(A|P)M\b/, "$1.m.").toLowerCase()}
                    </div>
                  )}
                  {item.headlines.basic}
                </a>
              </li>
            );
          return null;
        })}
      </ul>
    </Fragment>
  );
};

LiveTicker.propTypes = {
  url: PropTypes.string,
  isLive: PropTypes.bool,
  pollFrequency: PropTypes.number,
  subtypes: PropTypes.array,
  items: PropTypes.array,
  numToShow: PropTypes.number,
  showTimestamps: PropTypes.bool,
  compoundLabel: PropTypes.object
};

export default LiveTicker;
