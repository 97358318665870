import get from "lodash.get";
import getEnv from "~/components/utilities/env";
import usePolledData from "~/components/utilities/use-polled-data";

const ENDPOINT =
  "https://www.washingtonpost.com/arcio/fact-checker/item/?src_url=";

const getLocalEndpoint = (props) => {
  const { endpoint } = props;
  return `/api/live-ticker?source=${encodeURIComponent(endpoint)}`;
};

const resolve = (query = {}) => {
  const { url = "", subtypes = [], env = getEnv() } = query;

  const endpoint = `${ENDPOINT}${url.split("#")[0]}&subtypes=${subtypes.join(
    ","
  )}`;

  // NOTE: bypass CORS error selectively
  return env === "local" ? getLocalEndpoint({ ...query, endpoint }) : endpoint;
};

const transform = (data) => ({
  items: get(data, "latest.content.children", []).map((item) => ({
    canonical_url: item.canonical_url,
    display_date: item.cms_modified,
    headlines: {
      basic: item.transformed_content.headlines.basic
    }
  }))
});

export const useLiveTicker = (props = {}) => {
  const { url = "", subtypes = [], isLive, ...rest } = props;
  const endpoint = isLive ? resolve({ url, subtypes }) : undefined;
  const data = usePolledData({ endpoint, ...rest });
  return transform(data);
};

export default {
  resolve,
  transform,
  params: {
    url: "number",
    subtypes: "array",
    env: "text"
  }
};
